import React from 'react';
import _ from 'lodash';

import {Layout} from '../components/index';

export default class Store extends React.Component {
    render() {
        return (
            <Layout {...this.props}>
            <div className="outer-frame">
              {_.get(this.props, 'pageContext.frontmatter.storeLink') && 
              <iframe src={_.get(this.props, 'pageContext.frontmatter.storeLink')} className="inner-frame">
              </iframe>
              }
            </div>
            </Layout>
        );
    }
}
